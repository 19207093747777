import { Box, InputBase, makeStyles, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import cx from "classnames";

import SliderInput from "./SliderInput";
import { useCommonStyles } from "../../../../utils/loan-application.styles";
import { borderColor } from "../../../../scss/colors.scss";

const useStyles = makeStyles({
  interestRateInput: {
    padding: "20px 25px",
    width: "112px",
    border: `1px solid ${borderColor}`,
    borderRadius: "2px",
    "& input": {
      padding: "0",
      width: "fit-content",
    },
  },
});

const InterestRateInput = ({
  value,
  onChange,
  minValue = 0,
  maxValue = 10,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [displayValue, setDisplayValue] = useState(value); // use displayValue to separate the input value from the actual value for logic handling

  const handleSliderChange = useCallback((sliderValue) => {
    setDisplayValue(sliderValue);
    onChange(sliderValue);
  }, []);

  const validateInput = (inputValue) => {
    if (inputValue === "") return true;

    const numValue = parseFloat(inputValue);
    if (isNaN(numValue)) return false;

    // Check if it's within the range and has the correct precision
    const regex = /^(10(\.0)?|\d(\.\d?)?)$/;
    return regex.test(inputValue) && numValue >= 0 && numValue <= 10;
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (validateInput(newValue)) {
      setDisplayValue(newValue);
      const parsedValue = parseFloat(newValue);
      onChange(!isNaN(parsedValue) ? parsedValue : 0);
    }
  };

  const handleInputBlur = (event) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setDisplayValue(0);
    } else if (inputValue.slice(-1) === ".") {
      setDisplayValue(inputValue.slice(0, -1));
    }
  };

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography
        classes={{
          root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
        }}
      >
        Current Interest Rate
      </Typography>
      <Box display="flex" gridColumnGap="24px" alignItems="center">
        <Box
          display="flex"
          gridColumnGap="8px"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputBase
            type="text"
            value={displayValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            classes={{
              root: classes.interestRateInput,
              input: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
            endAdornment={
              <Typography
                classes={{
                  root: cx(
                    commonClasses.fontSize14,
                    commonClasses.fontWeightBold
                  ),
                }}
              >
                %
              </Typography>
            }
          />
        </Box>
        <SliderInput
          onChange={handleSliderChange}
          min={minValue}
          max={maxValue}
          value={value}
          isDecimal
          step={0.1}
        />
      </Box>
    </Box>
  );
};

export default InterestRateInput;
